import { Vue, Component } from 'vue-property-decorator';
const Content = () => import(`@/assets/docs/${'privacy'}.md`);

@Component({
  head() {
    return {
      title: 'PRIVACY POLICY',
    };
  },

  components: {
    Content,
  },
})
export default class Privacy extends Vue {

}
